import { createReducer } from '@reduxjs/toolkit';
import {
  ADD_NEW_SUITE,
  DELETE_A_SUITE,
  EDIT_A_SUITE,
  GET_A_SUITE,
  GET_DEVICES_FOR_SUITES,
  REMOVE_SEARCH_TEXT_SUITE,
  RESET_CSV_SUITES,
  SET_CSV_SUITES,
  SET_SEARCH_TEXT_SUITE,
  SYNC_YARDI_SUITES
} from '../constants';
import { requestSuccess, requestFail, requestPending } from '../../utils/status';

const initialState = {
  status: '',
  error: null,
  devices: null,
  csvSuites: [],
  suite: null,
  searchText: null
};

export default createReducer(initialState, {
  [SET_CSV_SUITES]: (state, { payload }) => {
    return {
      ...state,
      csvSuites: payload
    };
  },

  [RESET_CSV_SUITES]: (state) => {
    return {
      ...state,
      csvSuites: [],
      status: '',
      error: null
    };
  },

  [SET_SEARCH_TEXT_SUITE]: (state, { payload }) => {
    return {
      ...state,
      searchText: payload
    };
  },

  [REMOVE_SEARCH_TEXT_SUITE]: (state) => {
    return {
      ...state,
      searchText: null
    };
  },

  [requestSuccess(ADD_NEW_SUITE)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_NEW_SUITE),
    error: ''
  }),

  [requestPending(ADD_NEW_SUITE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_NEW_SUITE)
  }),

  [requestFail(ADD_NEW_SUITE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_NEW_SUITE),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_DEVICES_FOR_SUITES)]: (state, { payload }) => ({
    ...state,
    devices: payload,
    status: requestSuccess(GET_DEVICES_FOR_SUITES),
    error: null
  }),

  [requestPending(GET_DEVICES_FOR_SUITES)]: (state) => ({
    ...state,
    status: requestPending(GET_DEVICES_FOR_SUITES)
  }),

  [requestFail(GET_DEVICES_FOR_SUITES)]: (state, { payload }) => ({
    ...state,
    devices: null,
    status: requestFail(GET_DEVICES_FOR_SUITES),
    error: payload?.message
  }),

  [requestSuccess(DELETE_A_SUITE)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(DELETE_A_SUITE),
    error: ''
  }),

  [requestPending(DELETE_A_SUITE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(DELETE_A_SUITE)
  }),

  [requestFail(DELETE_A_SUITE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(DELETE_A_SUITE),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_A_SUITE)]: (state, { payload }) => ({
    ...state,
    suite: payload,
    status: requestSuccess(GET_A_SUITE),
    error: null
  }),

  [requestPending(GET_A_SUITE)]: (state) => ({
    ...state,
    status: requestPending(GET_A_SUITE)
  }),

  [requestFail(GET_A_SUITE)]: (state, { payload }) => ({
    ...state,
    suite: null,
    status: requestFail(GET_A_SUITE),
    error: payload?.message
  }),

  [requestSuccess(EDIT_A_SUITE)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_A_SUITE),
    error: ''
  }),

  [requestPending(EDIT_A_SUITE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_A_SUITE)
  }),

  [requestFail(EDIT_A_SUITE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_A_SUITE),
    error: payload?.data?.message
  }),

  [requestSuccess(SYNC_YARDI_SUITES)]: (state) => ({
    ...state,
    status: requestSuccess(SYNC_YARDI_SUITES),
    error: ''
  }),

  [requestPending(SYNC_YARDI_SUITES)]: (state) => ({
    ...state,
    status: requestPending(SYNC_YARDI_SUITES)
  }),

  [requestFail(SYNC_YARDI_SUITES)]: (state, { payload }) => ({
    ...state,
    status: requestFail(SYNC_YARDI_SUITES),
    error: payload?.data?.message
  })
});
