import { createReducer } from '@reduxjs/toolkit';
import {
  GET_COMMON_ACCESS,
  CLEAR_ERROR,
  SET_COMMON_ACCESS,
  GET_BUILDING_CAMERAS,
  GET_USER_FAVORITE_CAMERAS,
  ADD_USER_FAVORITE_CAMERA,
  REMOVE_USER_FAVORITE_CAMERA
} from '../constants';
import { requestFail, requestPending, requestSuccess } from '../../utils/status';

const initialState = {
  getCommonAccessStatus: '',
  getCommonAccessError: null,
  getCommonAccessData: [],

  getBuildingCamerasStatus: '',
  getBuildingCamerasError: null,
  getBuildingCamerasData: [],

  favoriteCameras: [],

  getFavoriteCamerasStatus: '',
  getFavoriteCamerasError: null,

  addFavoriteCamerasStatus: '',
  addFavoriteCamerasError: null,

  removeFavoriteCamerasStatus: '',
  removeFavoriteCamerasError: null
  // id: null,
  // display_name: "",
  // type_id: null,
  // camera_id: null,
  // video_url: '',
  // video_app: '',
  // video_stream: ''
};

export default createReducer(initialState, {
  [CLEAR_ERROR]: (state) => ({
    ...state,
    getCommonAccessError: null
  }),

  [requestSuccess(GET_COMMON_ACCESS)]: (state, { payload }) => {
    return {
      ...state,
      getCommonAccessStatus: requestSuccess(GET_COMMON_ACCESS),
      getCommonAccessData: payload,
      getCommonAccessError: null
    };
  },

  [requestPending(GET_COMMON_ACCESS)]: (state, { payload }) => ({
    ...state,
    getCommonAccessStatus: requestPending(GET_COMMON_ACCESS)
  }),

  [requestFail(GET_COMMON_ACCESS)]: (state, { payload }) => ({
    ...state,
    getCommonAccessStatus: requestFail(GET_COMMON_ACCESS),
    getCommonAccessError: payload.data,
    getCommonAccessData: []
  }),

  [SET_COMMON_ACCESS]: (state, { payload }) => ({
    ...state,
    getCommonAccessData: payload
  }),

  [requestSuccess(GET_BUILDING_CAMERAS)]: (state, { payload }) => {
    return {
      ...state,
      getBuildingCamerasStatus: requestSuccess(GET_BUILDING_CAMERAS),
      getBuildingCamerasData: payload.map((camera) => ({
        id: camera?.id,
        display_name: camera?.name,
        photo: '',
        type_id: 2,
        camera_id: camera?.id,
        video_url: camera?.webrtc_url,
        video_app: camera?.webrtc_app,
        video_stream: camera?.stream_name
      })),
      getBuildingCamerasError: null
    };
  },

  [requestPending(GET_BUILDING_CAMERAS)]: (state, { payload }) => ({
    ...state,
    getBuildingCamerasStatus: requestPending(GET_BUILDING_CAMERAS)
  }),

  [requestFail(GET_BUILDING_CAMERAS)]: (state, { payload }) => ({
    ...state,
    getBuildingCamerasStatus: requestFail(GET_BUILDING_CAMERAS),
    getBuildingCamerasError: payload.data,
    getBuildingCamerasData: []
  }),

  [requestSuccess(GET_USER_FAVORITE_CAMERAS)]: (state, { payload }) => {
    return {
      ...state,
      getFavoriteCamerasStatus: requestSuccess(GET_USER_FAVORITE_CAMERAS),
      favoriteCameras: payload.map((camera) => ({
        id: camera.id,
        cameraId: camera.camera_id,
        user_id: camera.user_id
      })),
      getFavoriteCamerasError: null
    };
  },

  [requestPending(GET_USER_FAVORITE_CAMERAS)]: (state) => ({
    ...state,
    getFavoriteCamerasStatus: requestPending(GET_USER_FAVORITE_CAMERAS),
    getFavoriteCamerasError: null
  }),

  [requestFail(GET_USER_FAVORITE_CAMERAS)]: (state) => ({
    ...state,
    getFavoriteCamerasStatus: requestFail(GET_USER_FAVORITE_CAMERAS),
    getFavoriteCamerasError: requestFail(GET_USER_FAVORITE_CAMERAS),
    favoriteCameras: []
  }),

  // ADD_USER_FAVORITE_CAMERA

  [requestSuccess(ADD_USER_FAVORITE_CAMERA)]: (state, { payload }) => {
    return {
      ...state,
      addFavoriteCamerasStatus: requestSuccess(ADD_USER_FAVORITE_CAMERA),
      favoriteCameras: state.favoriteCameras.concat({
        id: payload.id,
        cameraId: payload.camera_id,
        user_id: payload.user_id
      }),
      addFavoriteCamerasError: null
    };
  },

  [requestPending(ADD_USER_FAVORITE_CAMERA)]: (state) => ({
    ...state,
    addFavoriteCamerasStatus: requestPending(ADD_USER_FAVORITE_CAMERA),
    addFavoriteCamerasError: null
  }),

  [requestFail(ADD_USER_FAVORITE_CAMERA)]: (state) => ({
    ...state,
    addFavoriteCamerasStatus: requestFail(ADD_USER_FAVORITE_CAMERA),
    addFavoriteCamerasError: requestFail(ADD_USER_FAVORITE_CAMERA)
  }),

  // REMOVE_USER_FAVORITE_CAMERA

  [requestSuccess(REMOVE_USER_FAVORITE_CAMERA)]: (state, { payload }) => {
    return {
      ...state,
      removeFavoriteCamerasStatus: requestSuccess(REMOVE_USER_FAVORITE_CAMERA),
      favoriteCameras: state.favoriteCameras.filter((camera) => camera.id !== payload.id),
      removeFavoriteCamerasError: null
    };
  },

  [requestPending(REMOVE_USER_FAVORITE_CAMERA)]: (state) => ({
    ...state,
    removeFavoriteCamerasStatus: requestPending(REMOVE_USER_FAVORITE_CAMERA),
    removeFavoriteCamerasError: null
  }),

  [requestFail(REMOVE_USER_FAVORITE_CAMERA)]: (state) => ({
    ...state,
    removeFavoriteCamerasStatus: requestFail(REMOVE_USER_FAVORITE_CAMERA),
    removeFavoriteCamerasError: requestFail(REMOVE_USER_FAVORITE_CAMERA)
  })
});
