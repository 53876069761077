import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeActiveModal } from '../../helper/modals';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalButtons, ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormMessage,
  FormItem
} from '../../components/ui/Form';
import { Select, SelectItem } from '../../components/ui/Select';
import { SettingsModalInput } from '../settings/SettingsModals';
import { createLicensePlate, updateLicensePlate } from '../../redux/actions/licensePlates';
import { setToast } from '../../redux/actions/window';
import { getDDUnits } from '../../redux/actions/unit';
import { searchTable, setSearchPagination, setSearchState } from '../../redux/actions/search';
import { getRoomUnitTerminology } from '../../utils/utils';
import { requestPending } from '../../utils/status';
import { CREATE_LICENSE_PLATE, GET_DD_UNITS, UPDATE_LICENSE_PLATE } from '../../redux/constants';
import { useGetConfigs } from '../../hooks/useGetConfigs';

const LICENSE_PLATE_REGEX = /^[A-Za-z0-9.-]+( [A-Za-z0-9.-]+)*$/;

const AddOrEditLicensePlateModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.Organization.organization);
  const { modal, data } = useSelector((state) => state.headerModal);
  const searchState = useSelector((state) => state.search);
  const { config } = useGetConfigs();

  const createLicensePlateStatus = useSelector(
    (state) => state.licensePlates.createLicensePlateStatus
  );
  const updateLicensePlateStatus = useSelector(
    (state) => state.licensePlates.updateLicensePlateStatus
  );
  const allBuildings = useSelector((state) => state.building.allBuildings);
  const allBuildingsStatus = useSelector((state) => state.building.allBuildingsStatus);

  const units = useSelector((state) => state.unit.unitsForDD);
  const allUnitsStatus = useSelector((state) => state.unit.getDDUnitsStatus);

  const schema = Yup.object().shape({
    building_id: Yup.number()
      .required(t('Select a building'))
      .positive(t('Select a building'))
      .integer(),
    unit_id: Yup.number().required(t('Select a unit')).positive(t('Select a unit')).integer(),
    license_plate: Yup.string()
      .required(t('License plate is required'))
      .max(16, t('No more than 16 characters'))
      .matches(
        LICENSE_PLATE_REGEX,
        t('License plate can only contain letters, numbers, spaces, dashes, and periods')
      )
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: data
      ? {
          building_id: data.building_id,
          unit_id: data.unit_id,
          license_plate: data.license_plate
        }
      : {}
  });

  const watchBuilding = form.watch('building_id');

  const refetchLicensePlates = () =>
    dispatch(
      searchTable({
        entity: data.fromScan ? 'license_plate_scans' : 'license_plates',
        orgID: organization.id,
        body: {
          search: searchState.search,
          minCreationDate: searchState.minCreationDate,
          maxCreationDate: searchState.maxCreationDate,
          category: searchState.category,
          building_id: searchState.building_id,

          filters: searchState.filters,
          ordering: searchState.ordering,
          pagination: searchState.pagination
        },
        success: (res) => {
          dispatch(
            setSearchState({
              currentData: res.data.data ?? [],
              currentPaginationData: res.data.pagination
            })
          );

          if (!res.data.data) {
            dispatch(
              setSearchPagination({
                page: 1,
                limit: 20
              })
            );
          }
        }
      })
    );

  const onSubmit = (values) => {
    const bodyValues = {
      ...values,
      license_plate: values.license_plate.replace(/\s/g, '')
    };
    if (data.isEditing) {
      dispatch(
        updateLicensePlate({
          orgID: organization.id,
          licensePlateID: data.id,
          body: bodyValues,
          success: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('License plate updated successfully')
              })
            );
            closeActiveModal();
            refetchLicensePlates();
          },
          fail: (res) =>
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: `${t('Failed to update license plate')}: ${res?.data?.message}`,
                modal: 'errorToast'
              })
            )
        })
      );
    } else {
      dispatch(
        createLicensePlate({
          orgID: organization.id,
          body: bodyValues,
          success: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('License plate created successfully')
              })
            );
            closeActiveModal();
            refetchLicensePlates();
          },
          fail: (res) =>
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: `${t('Failed to create license plate')}: ${res?.data?.message}`,
                modal: 'errorToast'
              })
            )
        })
      );
    }
  };

  useEffect(() => {
    if (watchBuilding) {
      dispatch(
        getDDUnits({
          id: watchBuilding
        })
      );
    }
  }, [watchBuilding]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <StyledModal
          show={modal === 'AddOrEditLicensePlateModal'}
          closeOnBackdrop={false}
          onClose={closeActiveModal}>
          <ModalHeader title={data.isEditing ? t('Edit license plate') : t('Add license plate')} />

          <StyledModalBody>
            <FormField
              control={form.control}
              defaultValue={data?.building_id}
              name="building_id"
              render={({ field }) => (
                <FormItem alt>
                  <FormLabel> {t('Building')}</FormLabel>
                  <FormControl>
                    <Select
                      {...field}
                      loading={allBuildingsStatus === requestPending('GET_ALL_BUILDINGS')}
                      onChange={(e) => {
                        form.resetField('unit_id', { defaultValue: null });
                        field.onChange(e);
                      }}
                      placeholder={
                        allBuildingsStatus === requestPending('GET_ALL_BUILDINGS')
                          ? t('Loading')
                          : t('Please select')
                      }>
                      {allBuildingsStatus === requestPending('GET_ALL_BUILDINGS') && (
                        <SelectItem value={-1}>{t('Loading')}</SelectItem>
                      )}

                      {allBuildings?.length > 0 ? (
                        <SelectItem value={-1}>{t('Please select')}</SelectItem>
                      ) : (
                        <SelectItem value={-1}>{t('No Buildings Available')}</SelectItem>
                      )}

                      {allBuildings.map((building) => {
                        if (!building?.config?.enable_license_plates) return;
                        return (
                          <SelectItem key={building.id} value={building.id}>
                            {building.name}
                          </SelectItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="unit_id"
              render={({ field }) => (
                <FormItem alt>
                  <FormLabel> {getRoomUnitTerminology(config)} </FormLabel>
                  <FormControl>
                    <Select
                      {...field}
                      disabled={
                        units.length === 0 ||
                        !watchBuilding ||
                        allUnitsStatus === requestPending(GET_DD_UNITS)
                      }
                      height={'56px'}
                      placeholder={
                        config?.suite_enabled ? 'Select Room Number' : 'Select Unit Number'
                      }>
                      <SelectItem value={0}>No Unit</SelectItem>
                      {units.map((unit) => (
                        <SelectItem key={unit.id} value={unit.id}>
                          {unit.unit_num}
                        </SelectItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="license_plate"
              render={({ field }) => (
                <FormItem alt>
                  <FormLabel>{t('License plate')}</FormLabel>
                  <FormControl>
                    <SettingsModalInput
                      type="text"
                      placeholder={t('License plate')}
                      autoComplete="off"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </StyledModalBody>
          <ModalButtons
            leftButtonText={t('Cancel')}
            leftButtonClick={closeActiveModal}
            rightButtonText={t('Confirm')}
            rightDisabled={
              !form.formState.isDirty ||
              form.formState.isSubmitting ||
              updateLicensePlateStatus === requestPending(UPDATE_LICENSE_PLATE) ||
              createLicensePlateStatus === requestPending(CREATE_LICENSE_PLATE)
            }
          />
        </StyledModal>
      </form>
    </Form>
  );
};

export default AddOrEditLicensePlateModal;
