import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useGetConfigs} from '../hooks/useGetConfigs';
import routes from '../routes/index';
import { UserRole } from '../views/users/constant';

function useRoutes(initialValue = false) {
  const Deliveries = React.lazy(() => import('../views/Deliveries/Deliveries.jsx'));

  const LicensePlates = React.lazy(() => import('../views/license-plates/LicensePlates.jsx'));
  const LicensePlateScans = React.lazy(() =>
    import('../views/license-plate-scans/LicensePlateScans.jsx')
  );
  const Security = React.lazy(() => import('../views/security/index.js'));

  const { organization } = useSelector((state) => state.Organization);
  const { orgConfig } = useGetConfigs();
  const { roleId } = useSelector((state) => state.auth);

  const [routesList, setRouteList] = useState([]);

  

  useEffect(() => {
    if (orgConfig != undefined) {
      //Add protected routes here
      const protectedRoutes = [
        {
          path: '/security',
          name: 'Security',
          component: Security,
          exact: true,
          viewable:
            roleId == UserRole.SUPER_ADMIN ? true : organization.config.enable_security_cameras
        },
        {
          path: '/deliveries',
          name: 'Deliveries',
          component: Deliveries,
          exact: true,
          viewable: roleId == UserRole.SUPER_ADMIN ? true : orgConfig?.enable_deliveries //use this boolean attribute to validate if the route is viewable
        },
        {
          path: '/parking',
          name: 'Parking',
          component: LicensePlates,
          exact: true,
          viewable:
            roleId === UserRole.SUPER_ADMIN ||
            (orgConfig?.enable_license_plates &&
              (roleId === UserRole.ADMIN || roleId === UserRole.MANAGER))
        },
        {
          path: '/parking-analytics',
          name: 'Parking Analytics',
          component: LicensePlateScans,
          exact: true,
          viewable:
            roleId === UserRole.SUPER_ADMIN ||
            (orgConfig?.enable_license_plates &&
              (roleId === UserRole.ADMIN || roleId === UserRole.MANAGER))
        }
      ];

      let newRouteList = protectedRoutes.concat(routes);
      setRouteList(newRouteList);
    }
  }, [organization]);

  return [routesList];
}

export default useRoutes;
