import React from 'react';
import { Select as SelectAntD } from 'antd';
import { Fonts, Palette } from '../utils';
import { IoIosCheckmark } from 'react-icons/io';
import styled from 'styled-components';
import { useState } from 'react';
import { ImSpinner11 } from 'react-icons/im';
import { IoChevronDownSharp } from 'react-icons/io5';

export const Select = ({ children, disabled, loading, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledInput
      onDropdownVisibleChange={setOpen}
      open={open}
      menuItemSelectedIcon={<IoIosCheckmark color="black" size={15} />}
      height={'56px'}
      disabled={disabled || loading}
      getPopupContainer={(trigger) => trigger.parentElement}
      dropdownStyle={{
        padding: 0,
        zIndex: 9999,
        backgroundColor: Palette.SOLITUDE,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        animationDuration: '0s',
        // transform: 'translateY(-15px)',
        boxShadow: 'none'
      }}
      placement="bottomRight"
      dropdownAlign={{
        offset: [0, -5],
        overflow: {
          adjustX: false,
          adjustY: false
        }
      }}
      dropdownRender={(menu) => <DropdownWrapper>{menu}</DropdownWrapper>}
      suffixIcon={
        loading ? <LoadingSpinner /> : <IoChevronDownSharp color={Palette.BLACK_50} size={15} />
      }
      {...props}>
      {children}
    </StyledInput>
  );
};

export const SelectItem = React.forwardRef(({ children, ...props }, ref) => (
  <Select.Option {...props}>{children}</Select.Option>
));

const selectTextStyles = `
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledInput = styled(SelectAntD)`
  width: ${(props) => (props.width ? props.width : '100%')};

  .ant-select-dropdown {
    left: 0 !important;
    width: 100% !important;
  }

  .ant-select-selector {
    color: ${Palette.BLACK_50};
    background-color: ${Palette.SOLITUDE} !important;
    align-items: center;
    white-space: nowrap;
    box-shadow: none !important;
    transition-duration: 50ms !important;
    padding: 0px 20px !important;

    height: ${(props) => (props.height ? props.height : '56px')} !important;

    border: ${(props) => (props.error ? `${Palette.ERROR} solid 2px` : `none`)} !important;
    border-radius: 10px !important;
    border-bottom-left-radius: ${(props) => (props.open ? '0px' : '10x')} !important;
    border-bottom-right-radius: ${(props) => (props.open ? '0px' : '10px')} !important;

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      color: ${Palette.BLACK_50};
      ${selectTextStyles}
    }
  }

  &.ant-select-open .ant-select-selector {
    background-color: ${Palette.WHITE} !important;
    color: ${Palette.BLACK};
  }

  .ant-select-selection-overflow-item {
    .ant-select-selection-item {
      display: inline-flex;
      align-items: center;
      border-radius: 5px;
      background-color: ${Palette.HAZY_BLUE} !important;
      padding: 4px 8px;
      align-items: center;
      gap: 8px;
    }

    .ant-select-selection-item-content {
      color: ${Palette.WHITE};

      font-family: 'Open Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    .anticon-close {
      svg {
        color: ${Palette.WHITE};
      }
    }
  }

  &.ant-select-open .ant-select-selector {
    border: ${Palette.BORDER_OUTLINE} solid 0.5px !important;
  }

  .ant-select-arrow {
    right: 20px;
  }

  &.ant-select-disabled {
    opacity: 50%;
  }

  ${({ cssOverride }) => cssOverride};
`;

const DropdownWrapper = styled.div`
  .rc-virtual-list-holder {
    border: ${Palette.BORDER_OUTLINE} solid 0.5px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .rc-virtual-list-holder-inner {
    .ant-select-item {
      font-family: ${Fonts.OPEN_SANS_FONT} !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 24px !important;

      color: ${Palette.BLACK};
      background-color: ${Palette.WHITE};
      padding: 8px 20px;

      &:hover {
        color: ${Palette.BLACK};
        background-color: ${Palette.CUSTOM_GRAY_2};
      }
    }
  }
`;

export const LoadingSpinner = styled(ImSpinner11)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
