import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../../../redux/actions/window';
import { list } from '../../../utils/list';
import {
  clearBuildingError,
  setbuildingImg,
  clearNewBuilding,
  setNewBuilding,
  incrementBuildingEventCount,
  setLoadingCachedBuildings,
  setCachedBuildings,
  addBuilding,
  setGlobalBuildingStatus,
  setGlobalBuildingId,
  setGlobalSelectedBuilding,
  setGlobalBuilding,
  getBuildings
} from '../../../redux/actions/building';
import { Formik } from 'formik';
import { CForm, CFormGroup } from '@coreui/react';
import * as Yup from 'yup';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import OrganizationDropDown from '../../../components/OrganizationDropDown';
import { defaultImgCDN } from '../../../helper';
import { useTranslation } from 'react-i18next';
import { zip } from '../../../helper/Regx';
import { UserRole } from '../../users/constant';
import AvatarCropper, { ErrorText } from '../../../components/utils/AvatarCropper';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../settings/SettingsModals';
import { Select, SelectItem } from '../../../components/ui/Select';
import {closeActiveModal} from '../../../helper/modals';
import {requestPending} from '../../../utils/status';
import {SET_GLOBAL_BUILDING} from '../../../redux/constants';
import {searchTable, setSearchPagination, setSearchState} from '../../../redux/actions/search';
import {setUserSearch} from '../../../redux/actions/user';

const AddBuilding = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orgId, roleId } = useSelector((state) => state.auth);
  const { AllOrganizations, organization, globalOrgId} = useSelector((state) => state.Organization);
  const { error, newBuilding } = useSelector((state) => state.building);
  const { modal } = useSelector((state) => state.headerModal);
  const searchState = useSelector((state) => state.search);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [newAvatar, setNewAvatar] = useState({
    image: null,
    name: ''
  });

  useEffect(()=>{
    if(Boolean(newBuilding?.newAvatar)){
      setNewAvatar(newBuilding?.newAvatar);
    }
  },[newBuilding])

  const editorRef = useRef(null);

  const inputOrder = ['org_id', 'name', 'country', 'city', 'state', 'zip', 'address'];

  const validationSchema = function (values) {
    return Yup.object().shape({
      name: Yup.string()
        .matches(/^[A-zÀ-ÿ0-9 _\- \.\,]{0,25}$/i, t('Only alphabets allowed'))
        .max(25, t('Not more than 25 characters'))
        .required(t('Name is required!')),
      address: Yup.string()
        .min(2, t('Address is required!'))
        .matches(/^[A-zÀ-ÿ0-9 _\- \.\,]{0,200}$/i, t('Incorrect Format,only alphanumeric values'))
        .max(200, t('Not more than 200 characters'))
        .required(t('Address is required!')),
      city: Yup.string()
        .matches(/^[a-zA-Z0-9., ]{0,25}$/i, t('Incorrect Format,only alphanumeric values'))
        .max(25, t('Not more than 25 characters'))
        .required(t('City is required!')),
      org_id: Yup.string()
        .test('is-required', t('Organization is required') + '!', (value, context) => {
          if (roleId !== UserRole.SUPER_ADMIN) return true;

          if (value < 0) return false;

          if (!value || value === '') return false;

          return true;
        })
        .typeError(t('Organization is required') + '!'),
      state: Yup.string()
        .required(t('State is required!'))
        .test('is-required', t('State is required!'), (value, context) => {
          if (value === 'Select one') return false;
          if (context.parent.country <= 0) return false;
          return true;
        }),

      zip: Yup.string()
        .matches(zip, t('Invalid ZIP/Postal code'))
        .min(4, t('Expects minimum 5 characters'))
        .max(10, t('Not more than 10 characters'))
        .required(t('Zip is required!')),
      country: Yup.string()
        .required(t('Country is required!'))
        .test('is-required', t('Country is required!'), (value, context) => {
          if (value <= 0) {
            context.parent.state = 'Select one';
            return false;
          }
          return true;
        })
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  const initialValues = {
    org_id: newBuilding?.org_id || (roleId !== UserRole.SUPER_ADMIN ? orgId : null),
    name: newBuilding?.name || '',
    address: newBuilding?.address || '',
    city: newBuilding?.city || '',
    zip: newBuilding?.zip || '',
    state: newBuilding?.state || '',
    country: newBuilding?.country || 'CA',
    photo: newBuilding?.photo || '',
    num_units: newBuilding?.num_units || 0
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    if (editorRef.current && newAvatar.image) {
      editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
        const imgData = new FormData();
        imgData.append('image', blob, newAvatar.name);
        dispatch(
          setbuildingImg({
            body: imgData,
            success: (res) => {
              submitAddBuildingForm(values, res.data.filename);
            },
            fails: () => {
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('Avatar update failed!'),
                  modal: 'errorToast'
                })
              );
              setIsSubmitting(false);
            }
          })
        );
      });
    } else {
      submitAddBuildingForm(values);
    }
  };

  const handleAddBuilding = (buildingData) => {
    dispatch(
      addBuilding({
        body: buildingData,
        success: (res) => {
          dispatch(incrementBuildingEventCount());
          if (roleId === UserRole.SUPER_ADMIN) {
            dispatch(setLoadingCachedBuildings({ orgId, isLoading: true }));
            dispatch(
              setCachedBuildings({
                id: orgId,
                success: () => {
                  dispatch(setLoadingCachedBuildings({ orgId, isLoading: false }));
                }
              })
            );
          }

          const valueOrgId = parseInt(buildingData.org_id, 10);

          if (globalOrgId === valueOrgId && roleId === UserRole.SUPER_ADMIN) {
            dispatch(
              setGlobalBuildingStatus({
                status: requestPending(SET_GLOBAL_BUILDING)
              })
            );
            dispatch(
              setGlobalBuilding({
                id: globalOrgId
              })
            );
            dispatch(
              setGlobalBuildingId({
                id: res.data.id
              })
            );
            dispatch(setGlobalSelectedBuilding(res.data));
          } else if (parseInt(orgId, 10) === valueOrgId && roleId === UserRole.ADMIN) {
            dispatch(
              setGlobalBuildingStatus({
                status: requestPending(SET_GLOBAL_BUILDING)
              })
            );
            dispatch(
              setGlobalBuilding({
                id: orgId
              })
            );
          }
          dispatch(
            setGlobalBuildingId({
              id: res.data.id
            })
          );
          dispatch(setGlobalSelectedBuilding(res.data));

          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );
          dispatch(
            searchTable({
              entity: 'buildings',
              orgID: globalOrgId,
              body: {
                search: searchState.search,
                minCreationDate: searchState.minCreationDate,
                maxCreationDate: searchState.maxCreationDate,
                category: searchState.category,
                building_id: searchState.building_id,

                filters: searchState.filters,
                ordering: searchState.ordering,
                pagination: searchState.pagination
              },
              success: (res) => {
                dispatch(
                  setSearchState({
                    currentData: res.data.data ?? [],
                    currentPaginationData: res.data.pagination
                  })
                );

                if (!res.data.data) {
                  dispatch(setUserSearch({ page: 1 }));
                  dispatch(
                    setSearchPagination({
                      page: 1,
                      limit: 20
                    })
                  );
                }
              }
            })
          );
          dispatch(
            getBuildings({
              id: buildingData.org_id,
              page: 1,
              success: (buildings) => {
                if (globalOrgId) {
                  // dispatch(setGlobalBuildingId({ globalBuildingId: buildings.data.id }));
                }
              }
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Building has been successfully created!')
            })
          );

          dispatch(clearNewBuilding({}));
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Building creating Failed!'),
              modal: 'errorToast'
            })
          );
        },
        finally: () => {
          setIsSubmitting(false);
        }
      })
    );
  };
  
  const submitAddBuildingForm = (values, filename) => {
    const num_units = parseInt(values.num_units);
    const orgId = parseInt(values.org_id, 10);
    const buildingData = {
      ...values,
      org_id: orgId,
      photo: filename ? filename : defaultImgCDN,
      num_units,
      name: values.name.trim(),
      address: values.address.trim(),
      city: values.city.trim(),
      newAvatar,
      config: newBuilding?.config || organization?.config
    };
    dispatch(
      setNewBuilding(buildingData)
    )

    if(roleId === UserRole.SUPER_ADMIN) {
      dispatch(
        changeOpenedModal({
          modal: 'addBuildingStep2'
        })
      );
    } else {
      handleAddBuilding(buildingData)
    }
  };

  const onClose = () => {
    setNewAvatar({
      image: null,
      name: ''
    });
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearNewBuilding());
    dispatch(clearBuildingError());
  };

  //generalized onChange function to use in all inputs
  const handleInputChange = (event, touched, setTouched, handleChange, minLen = 0) => {
    const inputName = event;
    const inputLen = event.length;

    if (inputLen > minLen) setTouched({ ...touched, [inputName]: true });
    handleChange(event);
  };

  //prevent triggering errors if no input inserted
  const handleInputOnBlur = (event, values, touched, handleBlur) => {
    const inputName = event;
    if (!values[inputName] && !touched[inputName]) handleBlur(event);
  };
  //trigger errors of required fields present before the input selected if any
  const handleInputOnFocus = (event, touched, setTouched) => {
    const inputName = event;
    const inputIndex = inputOrder.indexOf(inputName);
    if (inputIndex > 0) {
      const newTouched = {};
      inputOrder.slice(0, inputIndex).forEach((name) => {
        newTouched[name] = true;
      });
      setTouched({ ...touched, ...newTouched });
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}>
        {({
          values,
          setValues,
          dirty,
          errors,
          isValid,
          isSubmitting,
          resetForm,
          touched,
          setTouched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) =>
          modal && (
            <CForm onSubmit={handleSubmit} noValidate name="AddBulidingForm">
              <StyledModal
                show={modal === 'addBuilding' ? true : false}
                onClose={() => {
                  onClose();
                  resetForm();
                }}
                closeOnBackdrop={false}>
                <ModalHeader title={t('Add new building')} />

                <StyledModalBody>
                  <CFormGroup>
                    <AvatarCropper
                      name={'add-building'}
                      avatar={defaultImgCDN}
                      newAvatarState={{
                        newAvatar,
                        setNewAvatar
                      }}
                      editorRef={editorRef}
                      withPreview
                    />
                  </CFormGroup>
                  {roleId === UserRole.SUPER_ADMIN && (
                    <OrganizationDropDown
                      showAdd={true}
                      values={values}
                      touched={touched}
                      handleChange={(e) => handleInputChange(e, touched, setTouched, handleChange)}
                      handleBlur={(e) => handleInputOnBlur(e, values, touched, handleBlur)}
                      handleOnFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                      Organizations={AllOrganizations}
                      errors={errors}
                      roleId={roleId}
                      setValues={setValues}
                    />
                  )}

                  <InputSeparator>
                    <SettingsModalBoldText>{t('Building name')}</SettingsModalBoldText>
                    <SettingsModalInput
                      type="text"
                      id="name"
                      name="name"
                      placeholder={t('Enter building name')}
                      autoComplete="text"
                      error={errors.name}
                      required
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\s{2,}/, ' ');
                        handleInputChange(e, touched, setTouched, handleChange);
                      }}
                      onBlur={(e) => handleInputOnBlur(e, values, touched, handleBlur)}
                      onFocus={(e) => handleInputOnFocus(e, touched, setTouched)}
                      value={values.name}
                    />
                    {errors.name && <ErrorText>{errors.name}</ErrorText>}
                  </InputSeparator>

                  <InputSeparator>
                    <SettingsModalBoldText>{t('Country')}</SettingsModalBoldText>
                    <Select
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          state: '',
                          city: '',
                          country: e
                        });
                      }}
                      value={values.country}
                      onFocus={(e) => handleInputOnFocus(e, touched, setTouched)}
                      custom
                      name="country"
                      id="country"
                      valid={!errors.country && touched.country}
                      invalid={touched.country && !!errors.country}>
                      {list.map((item, index) => (
                        <SelectItem value={item.abbreviation} key={index + item.abbreviation}>
                          {item.country}
                        </SelectItem>
                      ))}
                    </Select>
                    {errors.country && <ErrorText>{errors.country}</ErrorText>}
                  </InputSeparator>

                  <InputSeparator>
                    <SettingsModalBoldText>{t('City')}</SettingsModalBoldText>
                    <SettingsModalInput
                      type="text"
                      id="city"
                      name="city"
                      placeholder={'ie., Toronto'}
                      autoComplete="text"
                      error={errors.city}
                      required
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\s{2,}/, ' ');
                        handleInputChange(e, touched, setTouched, handleChange);
                      }}
                      onBlur={(e) => handleInputOnBlur(e, values, touched, handleBlur)}
                      onFocus={(e) => handleInputOnFocus(e, touched, setTouched)}
                      value={values.city}
                    />

                    {errors.city && <ErrorText>{errors.city}</ErrorText>}
                  </InputSeparator>

                  <InputSeparator>
                    <SettingsModalBoldText>{t('State / Province')}</SettingsModalBoldText>
                    <Select
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          state: e
                        });
                      }}
                      custom
                      disabled={!values.country}
                      name="state"
                      id="state"
                      placeholder={t('Please Select')}
                      valid={!errors.state && touched.state && !errors.country}
                      error={touched.state && !!errors.state}
                      value={values.state || undefined}>
                      {list
                        .find((country) => {
                          return country.abbreviation === values.country;
                        })
                        ?.states.map((state, index) => (
                          <SelectItem value={state.abbreviation} key={index + state.abbreviation}>
                            {state.name}
                          </SelectItem>
                        ))}
                    </Select>
                    {errors.state && <ErrorText>{errors.state}</ErrorText>}
                  </InputSeparator>

                  <InputSeparator>
                    <SettingsModalBoldText>{t('Zip / Postal code')}</SettingsModalBoldText>
                    <SettingsModalInput
                      type="text"
                      id="zip"
                      name="zip"
                      placeholder="ie., M1R0E9"
                      autoComplete="text"
                      error={errors.zip}
                      required
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\s{2,}/, ' ');
                        handleInputChange(e, touched, setTouched, handleChange);
                      }}
                      onBlur={(e) => handleInputOnBlur(e, values, touched, handleBlur)}
                      onFocus={(e) => handleInputOnFocus(e, touched, setTouched)}
                      value={values.zip}
                    />
                    {errors.zip && <ErrorText>{errors.zip}</ErrorText>}
                  </InputSeparator>

                  <InputSeparator>
                    <SettingsModalBoldText>{t('Address')}</SettingsModalBoldText>
                    <SettingsModalInput
                      type="text"
                      id="address"
                      name="address"
                      placeholder="ie., 123 Main St."
                      autoComplete="text"
                      error={errors.address}
                      required
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\s{2,}/, ' ');
                        handleInputChange(e, touched, setTouched, handleChange);
                      }}
                      onBlur={(e) => handleInputOnBlur(e, values, touched, handleBlur)}
                      onFocus={(e) => handleInputOnFocus(e, touched, setTouched)}
                      value={values.address}
                    />
                    {errors.address && <ErrorText>{errors.address}</ErrorText>}
                  </InputSeparator>

                  {error && Object.keys(error).length ? (
                    <ErrorText>{error?.message}</ErrorText>
                  ) : (
                    ''
                  )}
                </StyledModalBody>
                <ModalButtons
                  leftButtonText={t('Cancel')}
                  leftButtonClick={() => closeActiveModal(onClose)}
                  rightButtonText={roleId === UserRole.SUPER_ADMIN ? t('Next') : t('Add')}
                />
              </StyledModal>
            </CForm>
          )
        }
      </Formik>
    </div>
  );
};

export default AddBuilding;
