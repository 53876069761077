import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  GET_COMMON_ACCESS,
  GET_BUILDING_CAMERAS,
  GET_USER_FAVORITE_CAMERAS,
  ADD_USER_FAVORITE_CAMERA,
  REMOVE_USER_FAVORITE_CAMERA
} from '../constants';
import apiCall from '../../utils/apiCall';
// import { gratafyURL } from "../../utils/axios";
const gratafyURL = process.env.REACT_APP_GRATAFY_URL; //'https://grata-admin-api-9q7yezmw.ue.gateway.dev'

const getCommonAccess = apiCall({
  type: GET_COMMON_ACCESS,
  method: 'get',
  path: ({ org_id, building_id }) => `/admin/port/common/access/${org_id}/${building_id}`,
  gratafyURL
});

const getBuildingCameras = apiCall({
  type: GET_BUILDING_CAMERAS,
  method: 'get',
  path: ({ org_id, building_id }) => `/admin/camera/org/${org_id}/building/${building_id}`,
  gratafyURL
});

const getFavoriteCameras = apiCall({
  type: GET_USER_FAVORITE_CAMERAS,
  method: 'get',
  path: () => `/favorite_cameras`
});

const addFavoriteCamera = apiCall({
  type: ADD_USER_FAVORITE_CAMERA,
  method: 'post',
  path: ({ cameraId }) => `/favorite_cameras/${cameraId}`
});

const removeFavoriteCamera = apiCall({
  type: REMOVE_USER_FAVORITE_CAMERA,
  method: 'delete',
  path: ({ cameraId }) => `/favorite_cameras/${cameraId}`
});

export default function* securitySaga() {
  yield takeLatest(GET_COMMON_ACCESS, getCommonAccess);
  yield takeLatest(GET_BUILDING_CAMERAS, getBuildingCameras);
  yield takeLatest(GET_USER_FAVORITE_CAMERAS, getFavoriteCameras);
  yield takeLatest(ADD_USER_FAVORITE_CAMERA, addFavoriteCamera);
  yield takeLatest(REMOVE_USER_FAVORITE_CAMERA, removeFavoriteCamera);
}
