import { Palette } from '../../components/utils';
export const IS_DAILY = 1;

export const LOCKS = 1;
export const SERVICE_REQUESTS = 2;
export const PINS = 3;
export const BOOKINGS = 4;
export const COMMUNICATIONS = 5;
export const DEALS = 6;
export const NOTICE = 7;
export const USERS = 8;
export const DOOR_UNLOCK = 9;

export const UNIT_DOOR = 10;

export const EventCommonAccessDeviceUnlock = 906;
export const EventCommonAccessDeviceLock = 907;

export const TURN_AROUND = 'TURN_AROUND';

export const DATA_CENTERS = [
  { key: 'can', country: 'canada' },
  { key: 'usa', country: 'united states' }
];

const someMetrics = (metrics, type) =>
  DATA_CENTERS.reduce(
    (accumulator, currentValue) => accumulator + metrics[`${type}_${currentValue.key}`],
    0
  );
export const TOTAL_METRICS_HELPER = {
  ACTIVATED_RESIDENTS: (dataCenter) => `activated_residents_${dataCenter}`,
  TOTAL_RESIDENTS: (dataCenter) => `total_residents_${dataCenter}`,
  TOTAL_UNITS: (dataCenter) => `total_units_${dataCenter}`,
  TOTAL_MANAGERS: (dataCenter) => `total_managers_${dataCenter}`,
  ACTIVATED_GUESTS: (dataCenter) => `activated_guests_${dataCenter}`,
  TOTAL_GUESTS: (dataCenter) => `total_guests_${dataCenter}`,
  TOTAL: (metrics) => ({
    activatedResidents: someMetrics(metrics, 'activated_residents'),
    totalResidents: someMetrics(metrics, 'total_residents'),
    totalUnits: someMetrics(metrics, 'total_units'),
    totalManagers: someMetrics(metrics, 'total_managers'),
    totalActivatedGuests: someMetrics(metrics, 'activated_guests'),
    totalGuests: someMetrics(metrics, 'total_guests')
  })
};

export const PIE_CHART_BG = {
  positive: '#2eb85c',
  negative: '#FF6384',
  gray: Palette.DARK_GRAY,
  blue: Palette.HAZY_BLUE,
  green: Palette.HAZY_GREEN,
  black: '#292D30',
  dark_blue: '#597CAA',
  gray_2: '#474D56',
  blue_2: '#548AD7',
  light_blue: '#34B3F1'
};

export const PIE_CHART_HOVER = {
  positive: '#2eb85c',
  negative: '#FF6384',
  gray: Palette.DARK_GRAY
};

export const REPORT_TABS = {
  daily: 'daily',
  activations: 'activations',
  analytics: 'analytics',
  dealsPlus: 'deals_plus',
  batteryAndActivity: 'battery_and_activity'
};

export const REPORT_TABS_LABEL = {
  daily: 'Reports',
  activations: 'Resident Activations',
  analytics: 'Grata Analytics',
  deals_plus: 'Grata Plus',
  battery_and_activity: 'Battery/Activity'
};
