import { takeLatest } from 'redux-saga/effects';
import {
  ADD_NEW_SUITE,
  GET_DEVICES_FOR_SUITES,
  DELETE_A_SUITE,
  GET_A_SUITE,
  EDIT_A_SUITE,
  SYNC_YARDI_SUITES
} from '../constants';
import apiCall, { HTTP_METHOD } from '../../utils/apiCall';

const addSuite = apiCall({
  type: ADD_NEW_SUITE,
  method: HTTP_METHOD.post,
  path: '/suites'
});

const getDevicesSuites = apiCall({
  type: GET_DEVICES_FOR_SUITES,
  method: HTTP_METHOD.get,
  path: ({ ids }) => `/devices?suites=${ids}`
});

const deleteASuite = apiCall({
  type: DELETE_A_SUITE,
  method: HTTP_METHOD.delete,
  path: ({ id }) => `/suites/${id}`
});

const getASuite = apiCall({
  type: GET_A_SUITE,
  method: HTTP_METHOD.get,
  path: ({ id }) => `/suites/${id}`
});

const editASuite = apiCall({
  type: EDIT_A_SUITE,
  method: HTTP_METHOD.put,
  path: ({ id }) => `/suites/${id}`
});

const syncYardiSuites = apiCall({
  type: SYNC_YARDI_SUITES,
  method: HTTP_METHOD.post,
  path: ({ orgId, buildingId }) => `/proc/sync-yardi-units?id=${orgId}&building_id=${buildingId}`
});

export default function* suitesSaga() {
  yield takeLatest(ADD_NEW_SUITE, addSuite);
  yield takeLatest(GET_DEVICES_FOR_SUITES, getDevicesSuites);
  yield takeLatest(DELETE_A_SUITE, deleteASuite);
  yield takeLatest(GET_A_SUITE, getASuite);
  yield takeLatest(EDIT_A_SUITE, editASuite);
  yield takeLatest(SYNC_YARDI_SUITES, syncYardiSuites);
}
