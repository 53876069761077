// https://ui.shadcn.com/docs/components/input
// https://www.radix-ui.com/themes/docs/components/text-field
import React from 'react';
import styled from 'styled-components';
import { Fonts, Palette } from '../utils';

export const InputRoot = styled.div`
  display: flex;
  align-items: stretch;
  font-style: normal;
  text-align: start;
`;

const StyledInputSlot = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: text;

  &:where(:not([data-side='right'])) {
    order: -1;
    margin-left: calc(1px * -1);
    margin-right: 0;
  }

  &:where([data-side='right']),
  :where(&:not([data-side='right'])) ~ &:where(:not([data-side='left'])) {
    order: 0;
    margin-left: 0;
    margin-right: calc(1px * -1);
  }
`;

export const TextFieldSlot = React.forwardRef((props, forwardedRef) => {
  const { side, ...rest } = props;
  return <StyledInputSlot data-side={side} ref={forwardedRef} {...rest} />;
});
TextFieldSlot.displayName = 'TextFieldSlot';

export const Input = styled.input`
  display: flex;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  /* border: 1px solid var(--input-border-color); */
  border-color: unset;
  background-color: ${Palette.WHITE};
  padding: 0.625rem;
  font-size: 0.875rem;
  color: inherit;

  &::file-selector-button {
    border: none;
    background-color: transparent;
    font-size: 0.875rem;
    font-weight: 500;
  }

  &::placeholder {
    color: ${Palette.BLACK_50};
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus-visible {
    outline: ${Palette.BLACK} solid 1px;
  }
`;
