import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Palette } from '../../../components/utils';
import { MdLabelImportant } from 'react-icons/md';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../settings/SettingsModals';
import { closeActiveModal, openModal } from '../../../helper/modals';
import {
  ToggleItem,
  ToggleItemComponentContainer,
  ToggleItemDescription,
  ToggleItemTextContainer,
  ToggleItemTitle
} from '../ManageUserComponents';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ErrorText } from '../../../components/utils/AvatarCropper';
import { updateOrganizationData } from '../../../redux/actions/addOrganization';
import { FeatureSwitch } from '../../offers/styles';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';
import { range } from 'lodash';
import { Select, SelectItem } from '../../../components/ui/Select';

const USER_PIN_LENGTH_DROPDOWN_OPTIONS = range(4, 11);

const AddOrganizationModalStep2 = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const addOrganization = useSelector((state) => state.addOrganization);
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    enablePost: Yup.boolean().required(t('Posts Options is required')),
    enableOffers: Yup.boolean().required(t('Offers Options is required')),
    enableBooking: Yup.boolean().required(t('Bookings is required')),
    enableLockScreen: Yup.boolean().required(t('Lock Screen Option is required')),
    enableServiceRequest: Yup.boolean().required(t('Service Request Option is required')),
    enableReadOnlyUsers: Yup.boolean().required(t('Read Only Users Option is required')),
    enableShowSuperPinAdmin: Yup.boolean().required(t('Show Super Pin Admin Option is required')),
    enableEdit: Yup.boolean().required(t('Edit Option is required')),
    enableShowUserPinResident: Yup.boolean().required(
      t('Show User Pin Resident Option is required')
    ),
    enableSecurity: Yup.boolean().required(t('Enable Security Option is required')),
    enableMultiLock: Yup.boolean().required(t('Enable Multi Lock Option is required')),
    enableThermostats: Yup.boolean().required(t('Enable Thermostats Option is required')),
    enableLeakDetectors: Yup.boolean().required(t('Enable Leak Detectors Option is required')),
    enableCustomServiceRequestCategories: Yup.boolean().required(
      t('Enable Custom Service Request Categories Option is required')
    ),
    enableDeliveries: Yup.boolean().required(t('Enable Deliveries Option is required')),
    enableAkuvox: Yup.boolean().required(t('Enable Akuvox Option is required')),
    enableSecurityCameras: Yup.boolean().required(t('Enable Security Cameras Option is required')),
    showUserPinToAdmin: Yup.boolean().required(t('Show User Pin To Admin Option is required')),
    enableLicensePlates: Yup.boolean().required(t('Enable License Plates Option is required')),
    // enableAddGuests: Yup.boolean().required(t('Enable Add Guests Option is required')),
    serviceRequestURL: Yup.string().url('Invalid URL'),

    userPinLength: Yup.number()
      .required('User Pin Length is required')
      .typeError('User Pin must be a number')
      .min(4, t('Minimum Pin Length is 4'))
      .max(10, t('Maximum Pin Length is 10'))
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const onSubmit = (data) => {
    dispatch(
      updateOrganizationData({
        serviceRequestURL: data.serviceRequestURL,
        userPinLength: data.userPinLength,
        options: {
          ...addOrganization.options,
          enablePost: data.enablePost,
          enableOffers: data.enableOffers,
          enableBooking: data.enableBooking,
          enableLockScreen: data.enableLockScreen,
          enableServiceRequest: data.enableServiceRequest,
          enableReadOnlyUsers: data.enableReadOnlyUsers,
          enableShowSuperPinAdmin: data.enableShowSuperPinAdmin,
          enableEdit: data.enableEdit,
          enableMultiLock: data.enableMultiLock,
          enableThermostats: data.enableThermostats,
          enableLeakDetectors: data.enableLeakDetectors,
          enableCustomServiceRequestCategories: data.enableCustomServiceRequestCategories,
          enableDeliveries: data.enableDeliveries,
          enableAkuvox: data.enableAkuvox,
          enableSecurityCameras: data.enableSecurityCameras,
          enableShowUserPinResident: data.enableShowUserPinResident,
          showUserPinToAdmin: data.showUserPinToAdmin,
          enableSecurity: data.enableSecurity,
          enableLicensePlates: data.enableLicensePlates,
          enableAddGuests: data.enableAddGuests
        }
      })
    );
    openModal('AddOrganizationModalStep3');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledModal
        show={modal === 'AddOrganizationModalStep2'}
        closeOnBackdrop={false}
        onClose={closeActiveModal}>
        <ModalHeader title={t('Add Organization')} />

        <StyledModalBody>
          <Controller
            control={control}
            defaultValue={addOrganization.options.enablePost}
            name="enablePost"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Post')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Post')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableOffers}
            name="enableOffers"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Offers')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Offers')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableBooking}
            name="enableBooking"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Booking')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Booking')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableLockScreen}
            name="enableLockScreen"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Lock Screen')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Lock Screen')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableServiceRequest}
            name="enableServiceRequest"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Service Requests')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Service Requests')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableReadOnlyUsers}
            name="enableReadOnlyUsers"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Read Only Users')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Read Only Users')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableShowSuperPinAdmin}
            name="enableShowSuperPinAdmin"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Show Super PIN (Admin)')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Show Super PIN (Admin)')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableEdit}
            name="enableEdit"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Allow Edit')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Allow Edit')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableShowUserPinResident}
            name="enableShowUserPinResident"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Show User Pin (Resident)')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Show User Pin (Resident)')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableSecurity}
            name="enableSecurity"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Security')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Security')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableThermostats}
            name="enableThermostats"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Thermostats')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Thermostats')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableLeakDetectors}
            name="enableLeakDetectors"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Leak Detectors')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Leak Detectors')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            defaultValue={addOrganization.options.enableCustomServiceRequestCategories}
            name="enableCustomServiceRequestCategories"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Custom Service Request Categories')}</ToggleItemTitle>
                  <ToggleItemDescription>
                    {t('Enable Custom Service Request Categories')}
                  </ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            defaultValue={addOrganization.options.enableDeliveries}
            name="enableDeliveries"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Deliveries')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Deliveries')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            defaultValue={addOrganization.options.enableAkuvox}
            name="enableAkuvox"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Akuvox')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Akuvox')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            defaultValue={addOrganization.options.enableSecurityCameras}
            name="enableSecurityCameras"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Security Cameras')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Security Cameras')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            defaultValue={addOrganization.options.showUserPinToAdmin}
            name="showUserPinToAdmin"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Show User Pin To Admin')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Show User Pin To Admin')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            defaultValue={addOrganization.options.enableMultiLock}
            name="enableMultiLock"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Multi Lock')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Multi Lock')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            defaultValue={addOrganization.options.enableLicensePlates}
            name="enableLicensePlates"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable License Plates')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable License Plates')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          {/* <Controller
            control={control}
            defaultValue={addOrganization.options.enableAddGuests}
            name="enableAddGuests"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Add Guests')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Add Guests')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          /> */}

          <InputSeparator>
            <SettingsModalBoldText>{t('Service Request URL')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.serviceRequestURL}
              name="serviceRequestURL"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <SettingsModalInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    type="text"
                    name={name}
                    error={!!errors.serviceRequestURL}
                    placeholder={t('Service Request URL')}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>
          <InputSeparator>
            <SettingsModalBoldText>{t('User PIN Length')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.userPinLength}
              name="userPinLength"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <Select
                    onChange={onChange}
                    defaultValue={value}
                    value={value}
                    placeholder={t('Please Select')}
                    error={!!errors.userPinLength}>
                    {USER_PIN_LENGTH_DROPDOWN_OPTIONS.map((pinNum) => (
                      <SelectItem key={pinNum} value={pinNum}>
                        {pinNum}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>
          <div className="flex align-items-center" style={{ gap: '20px' }}>
            <MdLabelImportant size={55} color={Palette.HAZY_BLUE} />
            <ToggleItemDescription>
              {t('PIN length must be a minimum of 4 and a maximum of 10 characters long.')}
            </ToggleItemDescription>
          </div>
        </StyledModalBody>
        <ModalButtons
          leftButtonText={t('Back')}
          leftButtonClick={() => openModal('AddOrganizationModalStep1')}
          rightButtonText={t('Next')}
        />
      </StyledModal>
    </form>
  );
};

export default AddOrganizationModalStep2;
