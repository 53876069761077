import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledSidebarNavItem } from './SidebarComponents';
import { getOrganization } from '../../redux/actions/organization';
import { useResetAllPaginations } from '../../hooks/useResetAllPaginations';
import { closeActiveModal } from '../../helper/modals';
import styled from 'styled-components';
import { CImg } from '@coreui/react';
import { setGlobalOrganization } from '../../helper/store';
import { getServices } from '../../redux/actions/services';
import { getAllMessages } from '../../redux/actions/conversationSent';
import { getSelectedBookings } from '../../redux/actions/bookingParAm';
import { getDDUnits } from '../../redux/actions/unit';
import { setGlobalBuildingId, setGlobalSelectedBuilding } from '../../redux/actions/building';
import { TextInput } from '../../components/ui/TextInput';
import { Form, FormControl, FormField, FormItem } from '../../components/ui/Form';
import { Input } from '../../components/ui/Input';
import { useForm } from 'react-hook-form';
import { requestPending } from '../../utils/status';
import { GET_ALL_ORGANIZATIONS } from '../../redux/constants';

const NO_ORGANIZATION_SELECTION_OPTION = {
  id: 0,
  name: 'All Organizations'
};

const OrganizationListItem = ({ organization, toggleSidebar }) => {
  const { currentAmenityType, searchStatus } = useSelector((state) => state.services);
  const { globalBuildingId } = useSelector((state) => state.building);
  const { resetAllPaginations } = useResetAllPaginations();

  const dispatch = useDispatch();

  const changeOrganization = (organization) => {
    setGlobalOrganization(organization);
    resetAllPaginations();
    toggleSidebar();
    closeActiveModal();

    dispatch(getServices({ id: 0, page: 1, orgId: organization.id, status: searchStatus }));

    dispatch(
      setGlobalBuildingId({
        globalBuildingId: 0,
        globalBuildingName: ''
      })
    );

    dispatch(setGlobalSelectedBuilding(undefined));

    if (organization.id === 0) return;

    dispatch(getOrganization({ id: organization.id }));
    dispatch(
      getAllMessages({
        id: organization.id
      })
    );
    dispatch(
      getSelectedBookings({
        id: 0,
        page: 1,
        amenityId: currentAmenityType,
        orgId: organization.id
      })
    );

    if (globalBuildingId > 0) {
      dispatch(
        getDDUnits({
          id: globalBuildingId
        })
      );
    }
  };

  return (
    <a
      onClick={() => {
        changeOrganization(organization);
      }}>
      <StyledSidebarNavItem>
        <OrganizationLogo src={organization.logo || organization.photo} />
        {organization.name}
      </StyledSidebarNavItem>
    </a>
  );
};

export const OrganizationsListSidebar = ({ toggleSidebar }) => {
  const AllOrganizations = useSelector((state) => state.Organization.AllOrganizations);
  const getAllStatus = useSelector((state) => state.Organization.getAllStatus);
  const [filteredList, setFilteredList] = useState([NO_ORGANIZATION_SELECTION_OPTION]);

  const form = useForm({
    defaultValues: {
      search: ''
    }
  });

  const onSubmit = (data) => {
    const searchTerm = data.search.toLowerCase();

    if (!searchTerm) {
      setFilteredList([NO_ORGANIZATION_SELECTION_OPTION].concat(AllOrganizations || []));
      return;
    }

    const filtered =
      AllOrganizations?.filter((org) => org.name.toLowerCase().includes(searchTerm)) || [];

    setFilteredList([NO_ORGANIZATION_SELECTION_OPTION].concat(filtered));
  };

  useEffect(() => {
    if (AllOrganizations) {
      setFilteredList([NO_ORGANIZATION_SELECTION_OPTION].concat(AllOrganizations));
    }
  }, [AllOrganizations]);

  if (getAllStatus === requestPending(GET_ALL_ORGANIZATIONS)) {
    return null;
  }

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="search"
            render={({ field }) => (
              <SearchContainer>
                <FormItem>
                  <FormControl style={{ position: 'relative' }}>
                    <TextInput {...field} placeholder={'Search'} />
                  </FormControl>
                </FormItem>
              </SearchContainer>
            )}
          />
        </form>
      </Form>

      {filteredList.map((organization) => (
        <OrganizationListItem
          key={organization.id}
          organization={organization}
          toggleSidebar={toggleSidebar}
        />
      ))}
    </>
  );
};

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
`;

const OrganizationLogo = styled(CImg)`
  width: 50px;
  height: 50px;
`;
