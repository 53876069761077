import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  changeThePage,
  clearTree,
  resetDraftDetails,
  sendEmail,
  setFromTeam,
  setMedia,
} from '../../../redux/actions/conversationTemplate';
import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Palette } from '../../../components/utils';

const { confirm } = Modal;

const StyledAntDesignModal = styled(Modal)`
  background: red;

  .ant-modal-content {
    border-radius: 30px !important;
    background: ${Palette.GHOST_WHITE};
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 48px 46px !important;

    /* max-height: 85vh; */
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

const csss = css`
  background: red;
`;

// const StyledCModal = styled(CModal)`
//   transition: none;

//   .modal-dialog {
//   }

// .modal-content {
//   border-radius: 30px !important;
//   background: ${Palette.GHOST_WHITE};
//   display: flex;
//   flex-direction: column;
//   gap: 24px;
//   padding: 48px 46px !important;

//   /* max-height: 85vh; */
// }

//   .modal-dialog {
//     min-width: ${({ width }) => width || '548px'};
//     /* margin: auto auto; */
//     /*
//     display: flex;
//     align-items: center;
//     height: 100%; */

//     .modal-body {
//       padding-bottom: 0 !important;
//       padding-top: 0 !important;
//     }
//   }

//   ${({ cssOverride }) => cssOverride};
// `;

// export const StyledModalBody = styled(CModalBody)`
// display: flex;
// flex-direction: column;
// gap: ${({ gap }) => gap || '24px'};

//   ${({ cssOverride }) => cssOverride};
// `;

//if user close the editing of template without saving it a warning msg will be shown
function ModalConfirmation(dispatch, content, okText, t, cancelFunc, okFunc, title) {
  confirm({
    style: { top: 150 },
    wrapClassName: StyledAntDesignModal.styledComponentId,
    className: StyledAntDesignModal.styledComponentId,
    title: title ? title : t('You have unsaved changes'),
    icon: <ExclamationCircleOutlined />,
    content: content,
    okText: okText,
    cancelText: t('Exit'),
    onOk() {
      okFunc && okFunc();
    },
    async onCancel() {
      cancelFunc && cancelFunc();
      await dispatch(changeThePage({ page: '0' }));
      //TODO: need to call draft reducer action instead of sendEMail
      dispatch(
        sendEmail({
          templateSelected: '',
          templateData: ''
        })
      );
      dispatch(resetDraftDetails());
      dispatch(
        setMedia({
          image: null,
          name: '',
          url: ''
        })
      );
      dispatch(
        setFromTeam("")
      );
    }
  });
}
export default ModalConfirmation;
