import CIcon from '@coreui/icons-react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { freeSet } from '@coreui/icons';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { ReactComponent as ResendInviteIcon } from '../assets/icons/resendInvite.svg';
import { Icon, myIcons } from '../helper/icons';
import { AccountStatus, BUILDING_TYPE, DATE_FORMATS, UserRole, UserType } from '../views/users/constant';
import { RequestStatus } from '../views/services/constant';
import { RiPencilFill } from 'react-icons/ri';
import styled from 'styled-components';
import { Fonts, Palette } from './utils';
import { FaInfoCircle, FaLock, FaTrash } from 'react-icons/fa';
import { Separator } from './ui/Separator';
import { MdOutlineEmail } from 'react-icons/md';
import { IoKeypad } from 'react-icons/io5';
import { TIME_ZONES } from '../helper/constants';
import {useGetConfigs} from '../hooks/useGetConfigs';

export const TableName = {
  USERS: 'users',
  BOOKINGS: 'bookings',
  OFFERS: 'offers',
  AMENITIES: 'amenities',
  MESSAGE_SENT: 'messageSent',
  DRAFTS: 'drafts',
  REQUESTS: 'requests',
  BUILDINGS: 'buildings',
  SERVICES: 'services'
};

function DeleteDropdownOption({ setShowModal, text, item, permanentDelete = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <StyledDropdownItem
      className="deleteOption"
      onClick={() => {
        setShowModal(() => ({
          show: true,
          id: item.id,
          org_id: item.org_id,
          perm: true,
          permanentDelete: permanentDelete
        }));
        dispatch(
          changeOpenedModal({
            modal: 'deleteModal'
          })
        );
      }}>
      <FaTrash size={16} className="mr-2" /> {t(text)}
    </StyledDropdownItem>
  );
}

function GlobalTableEditDropdown({
  tableName,
  handleEdit,
  handleDetails,
  setShowModal,
  handleInvite,
  item,
  handleBooking,
  disabled,
  style,
  handleSend,
  handleDelete,
  permanentDelete,
  handleSendPin = () => {},
  handleResendPin = () => {}
}) {
  const { t } = useTranslation();

  const { profile } = useSelector((state) => state.profile);

  const roleId = parseInt(localStorage.getItem('roleId', 10)) || '';

  const { roleId: roleIdState } = useSelector((state) => state.auth);
  const {config} = useGetConfigs();

  const inFuture = moment(item.start_date).isSameOrAfter(moment());

  const checkHardDelete = (item) => {
    return roleId !== UserRole.SUPER_ADMIN && item.status_id == AccountStatus.DELETED;
  };

  const canDeleteUser = (item) => {
    if (profile.id && item?.id === profile.id) {
      return false;
    }
    if (config?.org_allow_edits === false) {
      return roleId >= UserRole.ACCOUNT_MGR;
    }

    if (checkHardDelete(item)) {
      return false;
    }

    return (
      (roleId !== UserRole.USER && roleId !== UserRole.ADVERTISER) ||
      config?.org_allow_edits === true ||
      item?.type_id > UserType.CORESIDENT
    );
  };

  const canEditUser = (item) => {
    return item.status_id != AccountStatus.DELETED;
  };

  const allowResendInvitation = (user) => {
    return (
      user.status_id != AccountStatus.ENABLED &&
      user.status_id != AccountStatus.PENDING &&
      user.status_id != AccountStatus.DELETED &&
      tableName === 'users' &&
      typeof handleInvite === 'function'
    );
  };

  const combineDateAndTime = (dateStr, timeStr, timezone) => {
    const date = moment.utc(dateStr);
    const time = moment.utc(timeStr);
    
    return moment.tz(timezone)
      .year(date.year())
      .month(date.month())
      .date(date.date())
      .hour(time.hour())
      .minute(time.minute())
      .second(time.second());
  };
  
  const isWithinReservation = useMemo(() => {
    if (!item?.check_in_date || !item?.check_out_date || !item?.check_in_time || !item?.check_out_time || !item?.building_state) {
      return false;
    }
  
    const reservationTimezone = TIME_ZONES.get(item.building_state);
    if (!reservationTimezone) {
      return false;
    }
  
    const now = moment().tz(reservationTimezone);
    const checkInDateTime = combineDateAndTime(item.check_in_date, item.check_in_time, reservationTimezone);
    const checkOutDateTime = combineDateAndTime(item.check_out_date, item.check_out_time, reservationTimezone);
    
    return now.isSameOrAfter(checkInDateTime) && now.isBefore(checkOutDateTime);
  }, [item?.check_in_date, item?.check_out_date, item?.check_in_time, item?.check_out_time, item?.building_state]);

  return (
    <CDropdown className="tableEditDropdown">
      <CDropdownToggle disabled={disabled} style={style}>
        <RiPencilFill size={20} />
      </CDropdownToggle>
      <StyledDropdown>
        {tableName === 'messageSent' ? (
          <>
            <StyledDropdownItem onClick={() => handleDetails(item.id)}>
              <FaInfoCircle size={16} className="mr-2" />
              {t('Details')}
            </StyledDropdownItem>
          </>
        ) : tableName === TableName.DELIVERY_CODES ? (
          <>
            <StyledDropdownItem onClick={() => handleDelete(item)}>
              <FaTrash size={16} className="mr-2" />
              {t('Delete')}
            </StyledDropdownItem>
          </>
        ) : (
          <>
            {' '}
            {tableName === 'amenities' ? (
              <>
                <StyledDropdownItem onClick={() => handleBooking(item.id, item.type_id)}>
                  {myIcons[parseInt(item.type_id)] ? (
                    <StyledIcon icon={myIcons[parseInt(item.type_id)]} className="mr-1" />
                  ) : (
                    <FaInfoCircle size={16} className="mr-2" />
                  )}{' '}
                  {t('book_amenity', { amenityLabel: item.type })}
                </StyledDropdownItem>
                <Separator orientation="horizontal" />
                <StyledDropdownItem onClick={() => handleEdit(item.id)} disabled={!inFuture}>
                  <RiPencilFill size={16} className="mr-2" /> {t('Edit')}
                </StyledDropdownItem>
                <Separator orientation="horizontal" />
              </>
            ) : (
              ''
            )}
            {tableName === 'users' && item.status_id === AccountStatus.DELETED ? (
              <></>
            ) : (
              <>
                <StyledDropdownItem onClick={() => handleDetails(item)}>
                  <FaInfoCircle size={16} className="mr-2" />
                  {t('Details')}
                </StyledDropdownItem>
              </>
            )}
            {parseInt(item.status, 10) !== 3 && tableName === 'bookings' ? (
              <>
                {parseInt(item.status, 10) !== 17 && (
                  <>
                    <Separator orientation="horizontal" />
                    <StyledDropdownItem
                      onClick={() => handleEdit(item, item.building_id)}
                      disabled={!inFuture}>
                      <RiPencilFill size={16} className="mr-2" /> {t('Edit')}
                    </StyledDropdownItem>
                  </>
                )}
              </>
            ) : tableName === 'offers' ? (
              <>
                <Separator orientation="horizontal" />
                <StyledDropdownItem onClick={() => handleEdit(item.id)}>
                  <RiPencilFill size={16} className="mr-2" /> {t('Edit')}
                </StyledDropdownItem>
              </>
            ) : tableName === TableName.DRAFTS ? (
              <>
                <Separator orientation="horizontal" />
                <StyledDropdownItem onClick={() => handleEdit(item)}>
                  <RiPencilFill size={16} className="mr-2" /> {t('Edit')}
                </StyledDropdownItem>
                <Separator orientation="horizontal" />
              </>
            ) : (
              (config?.org_allow_edits || item?.type_id === UserType.CORESIDENT) &&
              item?.status != 3 &&
              !(tableName === 'users' && item.status_id === AccountStatus.DELETED) &&
              canEditUser(item) &&
              item.role_id > 0 && (
                <div
                  style={{
                    cursor:
                      item.status === RequestStatus.ARCHIVED ||
                      item.status === RequestStatus.COMPLETE
                        ? 'not-allowed'
                        : 'pointer'
                  }}>
                  <>
                    <Separator orientation="horizontal" />
                    <StyledDropdownItem
                      disabled={
                        item.status === RequestStatus.ARCHIVED ||
                        item.status === RequestStatus.COMPLETE
                      }
                      onClick={() => handleEdit(item.id, item.status)}>
                      <RiPencilFill size={16} className="mr-2" />
                      {t('Edit')}
                    </StyledDropdownItem>
                  </>
                </div>
              )
            )}
            {roleId > UserRole.USER &&
              item?.unit_num &&
              (item.status_id == AccountStatus.ENABLED || item.status_id == AccountStatus.NEW) && (
                <>
                  {item.building_type == BUILDING_TYPE.TEMPORARY_RESIDENCY.id ? (
                    <>
                      {item?.role_id === UserRole.USER && isWithinReservation && (
                        <>
                          <Separator orientation="horizontal" />
                          <StyledDropdownItem onClick={() => handleResendPin(item)}>
                            <IoKeypad size={16} className="mr-2" />{' '}
                            {t('Resend Unit PIN (Hospitality)')}
                          </StyledDropdownItem>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Separator orientation="horizontal" />
                      <StyledDropdownItem onClick={() => handleSendPin(item.unit_id, item.org_id)}>
                        <FaLock size={16} className="mr-2" /> {t('Email User PIN')}
                      </StyledDropdownItem>
                    </>
                  )}
                </>
              )}
            {allowResendInvitation(item) && (
              <>
                <Separator orientation="horizontal" />
                <StyledDropdownItem onClick={() => handleInvite(item.id)}>
                  <MdOutlineEmail size={16} className="mr-2" />
                  {t('Resend invite')}
                </StyledDropdownItem>
              </>
            )}
            {tableName === 'users' ? (
              <>
                {
                  // keep the permanent delete option for admins to remove the soft deleted users
                  canDeleteUser(item) && (
                    <>
                      <Separator orientation="horizontal" />
                      <DeleteDropdownOption
                        setShowModal={setShowModal}
                        text={'Delete'}
                        item={item}
                        permanentDelete={permanentDelete}
                      />
                    </>
                  )
                }
              </>
            ) : (
              ''
            )}
            {tableName === 'services' &&
              item.status !== 'Archived' &&
              item.status !== 'Complete' && (
                <>
                  <Separator orientation="horizontal" />
                  <StyledDropdownItem onClick={() => handleEdit(item.id, item.status)}>
                    <RiPencilFill size={16} className="mr-2" />
                    {t('Edit')}
                  </StyledDropdownItem>
                </>
              )}
            {tableName === 'organization' && (
              <>
                <Separator orientation="horizontal" />
                <StyledDropdownItem onClick={() => handleEdit(item.id, item.status)}>
                  <RiPencilFill size={16} className="mr-2" />
                  {t('Edit')}
                </StyledDropdownItem>
                <Separator orientation="horizontal" />
                <StyledDropdownItem onClick={() => handleDelete(item)}>
                  <FaTrash size={16} className="mr-2" />
                  {t('Delete')}
                </StyledDropdownItem>
              </>
            )}
            {tableName === 'offers' && roleIdState >= UserRole.SUPER_ADMIN && (
              <>
                <Separator orientation="horizontal" />
                <DeleteDropdownOption setShowModal={setShowModal} text={'Delete'} item={item} />
              </>
            )}
          </>
        )}
        {tableName === TableName.DRAFTS && (
          <>
            <StyledDropdownItem onClick={() => handleSend(item.id)}>
              <MdOutlineEmail size={16} className="mr-2" />
              {t('Send')}
            </StyledDropdownItem>
            <Separator orientation="horizontal" />
            <StyledDropdownItem onClick={() => handleDelete(item.id)}>
              <FaTrash size={16} className="mr-2" />
              {t('Delete')}
            </StyledDropdownItem>
          </>
        )}
      </StyledDropdown>
    </CDropdown>
  );
}

export const StyledIcon = styled(Icon)`
  stroke: ${Palette.WHITE};
  :hover {
    stroke: ${Palette.OCEANIC_60} !important;
  }
`;

export const StyledDropdown = styled(CDropdownMenu)`
  cursor: auto;
  padding: 0 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;

  border-radius: 10px;
  background: ${Palette.HAZY_BLUE};
`;

export const StyledDropdownItem = styled(CDropdownItem)`
  padding: 12px 0;

  color: ${Palette.WHITE} !important;
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  pointer-events: all !important;
  opacity: ${(props) => (props.disabled ? '50%' : '100%')} !important;

  :hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')} !important;
    background-color: ${Palette.HAZY_BLUE};
    color: ${(props) => (props.disabled ? 'none' : Palette.OCEANIC_60)} !important;

    svg {
      stroke: ${Palette.OCEANIC_60} !important;
    }
  }
`;

export default GlobalTableEditDropdown;
