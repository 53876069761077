import React from 'react';
import { CRow, CCol } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import { getUser } from '../../../../redux/actions/user';
import LoadingAvatar from '../../../../components/LoadingAvatar';
import { setToast } from '../../../../redux/actions/window';
import { getUnit } from '../../../../redux/actions/unit';
import DetailsModalTitleButtons from '../../../../components/DetailsModalTitleButtons';
import { useTranslation } from 'react-i18next';
import { LOADING_BLUE } from '../../../../utils/colorCode';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../../../settings/SettingsItem';
import { FaBed, FaHouseUser, FaRulerCombined, FaShower, FaUser } from 'react-icons/fa';
import { Palette } from '../../../../components/utils';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import { BsBuildingsFill } from 'react-icons/bs';
import { HiOutlineSquare3Stack3D, HiOutlineUsers, HiAdjustmentsHorizontal } from 'react-icons/hi2';
import { MdAttachMoney } from 'react-icons/md';
import moment from 'moment';
import { DATE_FORMATS } from '../../../users/constant';
import {useGetConfigs} from '../../../../hooks/useGetConfigs';
import {SettingsModalBoldText} from '../../../settings/SettingsModals';
import {ToggleHelper} from '../../../manage-users/ManageUserComponents';

const UnitDetails = () => {
  const { t } = useTranslation();
  const { modal, data: showRoom } = useSelector((state) => state.headerModal);
  const { config } = useGetConfigs();
  const { unit, status } = useSelector((state) => state.unit);
  const dispatch = useDispatch();

  const unitLabels = {
    building: t('Building'),
    unit_no: showRoom ? t('Room number') : t('Unit number'),
    floor_no: t('Floor'),
    bedrooms: t('Bedrooms'),
    bathrooms: t('Bathrooms'),
    area: t('Area'),
    resident: t('Resident'),
    square_feet: t('Sq.ft'),
    monthly_rent: t('Monthly Rent'),
    primary_resident: t('Primary Resident'),
    co_residents: t('Co-Residents'),
    configuration: t('Configuration')
  };

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  const showUserDetail = (id) => {
    dispatch(
      getUser({
        id,
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: 'UserDetail'
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to get user!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };
  const handleClick = () => {
    onClose();
    dispatch(
      getUnit({
        id: unit.id,
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: 'editRoom'
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('failed to get unit data!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  return (
    <StyledModal
      show={modal === 'UnitDetail'}
      onClose={onClose}
      closeOnBackdrop={true}
      width={'450px'}>
      <ModalHeader title={showRoom ? `Room ${unit.unit_num}` : `Unit ${unit.unit_num}`} />
      {status === 'GET_UNIT_PENDING' ? (
        <LoadingAvatar color={LOADING_BLUE} />
      ) : (
        <>
          <StyledModalBody>
            <SettingsItem>
              <BsBuildingsFill size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{unitLabels.building}</SettingsItemTitle>
                <SettingsItemSubTitle>{unit?.building}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <FaHouseUser size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{unitLabels.unit_no}</SettingsItemTitle>
                <SettingsItemSubTitle>{unit?.unit_num}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <HiOutlineSquare3Stack3D size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{unitLabels.floor_no}</SettingsItemTitle>
                <SettingsItemSubTitle>{unit?.floor}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <FaRulerCombined size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{unitLabels.square_feet}</SettingsItemTitle>
                <SettingsItemSubTitle>{unit?.area}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <MdAttachMoney size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{unitLabels.monthly_rent}</SettingsItemTitle>
                <SettingsItemSubTitle>{unit?.rent}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <CRow>
              <CCol>
                <SettingsItem>
                  <FaBed size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{unitLabels.bedrooms}</SettingsItemTitle>
                    <SettingsItemSubTitle>{unit?.bedrooms}</SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>
              </CCol>
              <CCol>
                <SettingsItem>
                  <FaShower size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{unitLabels.bathrooms}</SettingsItemTitle>
                    <SettingsItemSubTitle>{unit?.bathrooms}</SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>
              </CCol>
            </CRow>

            <SettingsItem>
              <FaUser size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{unitLabels.resident}</SettingsItemTitle>
                <SettingsItemSubTitle
                  link={unit?.resident?.length > 0}
                  onClick={() => unit?.resident?.length && showUserDetail(unit.user_id)}>
                  {unit?.resident?.length ? unit.resident : 'None'}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            {unit?.reservations && unit.reservations.length > 0 ? (
              <>
                <SettingsItem>
                  <HiOutlineUsers size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>Reservations</SettingsItemTitle>
                    {unit.reservations.map((reservation) => (
                      <SettingsItemSubTitle
                        key={reservation.id}
                        link
                        onClick={() => showUserDetail(reservation.user_id)}>
                        {reservation.user_name}{' '}
                        {moment.utc(reservation.check_in_date).format(DATE_FORMATS.MM_DD_YYYY)} -{' '}
                        {moment.utc(reservation.check_out_date).format(DATE_FORMATS.MM_DD_YYYY)}
                      </SettingsItemSubTitle>
                    ))}
                  </SettingsItemContent>
                </SettingsItem>
              </>
            ) : unit?.residents ? (
              <>
                <SettingsItem>
                  <HiOutlineUsers size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{unitLabels.co_residents}</SettingsItemTitle>
                    {unit?.residents
                      ?.filter((o) => parseInt(o.type) !== 1)
                      .map((coUsers, index, array) => (
                        <SettingsItemSubTitle
                          key={coUsers.id}
                          link
                          onClick={() => showUserDetail(coUsers.id)}>
                          {coUsers.name}
                          {index < array.length - 1 ? ', ' : ''}
                        </SettingsItemSubTitle>
                      ))}
                  </SettingsItemContent>
                </SettingsItem>
              </>
            ) : null}
            <SettingsItem>
              <HiAdjustmentsHorizontal size={24} color={Palette.HAZY_BLUE}/>
              <SettingsItemContent>
                <SettingsItemTitle>{unitLabels.configuration}</SettingsItemTitle>
                <SettingsItem option cssOverride={"margin-top: 10px;"}>
                  <SettingsItemSubTitle>{t('Enable Add Guests')}</SettingsItemSubTitle>
                  <ToggleHelper enabled={unit?.config?.enable_add_guests} />
                </SettingsItem>
                <SettingsItem option cssOverride={"margin-top: 1px;"}>
                  <SettingsItemSubTitle>{t('Enable Guests CM Doors Access')}</SettingsItemSubTitle>
                  <ToggleHelper enabled={unit?.config?.enable_guests_access_common_doors} />
                </SettingsItem>
              </SettingsItemContent>
            </SettingsItem>

            {config?.org_allow_edits && (
              <DetailsModalTitleButtons
                modalName="units"
                className="editButtinDiv mb-3"
                colClassName="text-center editIconDiv"
                handleClick={handleClick}
                editText="Edit"
                unit={unit}
              />
            )}
          </StyledModalBody>
        </>
      )}
    </StyledModal>
  );
};

export default UnitDetails;
