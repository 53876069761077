import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CCardBody } from '@coreui/react';
import Amenities from './Amenities/Amenities';
import Unit from './Unit/Unit';
import {
  getBuilding,
  getBuildingSuites,
  resetSuitesInBuilding,
  showGlobalBuildingsDropdown
} from '../../../redux/actions/building';
import { clearAmenities, clearFilteredAmenities } from '../../../redux/actions/amenities';
import { setIsOnDashboard, setToast } from '../../../redux/actions/window';
import AddUnit from './Unit/AddUnit';
import ExportCSVButton from '../../../components/ExportCSVButton';
import GlobalAddButton from '../../../components/GlobalAddButton';
import { useTranslation } from 'react-i18next';
import ImportUnitsCSVButton from '../../../components/ImportUnitsCSVButton';
import { isYardiPropertyProvider, UserRole } from '../../../views/users/constant';
import {
  AddNewButton,
  TablePageHeader,
  TablePageTitle
} from '../../../components/tables/TableComponents';
import { OptionsContainer } from '../../manage-users/ManageUserComponents';
import { FaArrowLeft } from 'react-icons/fa';
import styled from 'styled-components';
import { openModal } from '../../../helper/modals';
import { StyledTabLabel, Tab, TabList } from '../../../containers/TheHeaderDropdownMssg';
import { StyledCard, StyledCardBody } from '../../../components/Cards';
import { getOrgConfig } from '../../../redux/actions/organization';
import Suites from './Suites/Suites';
import CustomSearchBar from '../../../components/CustomSearchBar';
import {
  resetCSVSuites,
  resetSearchTextSuite,
  setCSVSuites,
  setSearchTextSuite,
  syncYardiSuites
} from '../../../redux/actions/suites';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import BatteryStatusTable from './BatteryStatus/BatteryStatusTable';
import {useGetConfigs} from '../../../hooks/useGetConfigs';
import { Button, ButtonBody } from '../../../components/ui/Button';
import { requestPending } from '../../../utils/status';
import { SYNC_YARDI_SUITES } from '../../../redux/constants';

export const AVAILABLE_TABS = {
  SUITE: 'suite',
  UNITS: 'units',
  AMENITIES: 'amenities',
  BATTERY_STATUS: 'battery_status'
};

const FETCH_YARDI_UNITS_DELAY = 15000;

const UnitAmenities = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const building = useSelector((state) => state.building.building);
  const buildings = useSelector((state) => state.building.buildings);
  const filteredAmenities = useSelector((state) => state.amenities.filteredAmenities);
  const csvSuites = useSelector((state) => state.suites.csvSuites);
  const suitesStatus = useSelector((state) => state.suites.status);
  const searchText = useSelector((state) => state.suites.searchText);
  const units = useSelector((state) => state.unit.units);
  const roleId = useSelector((state) => state.auth.roleId);
  const { config } = useGetConfigs();
  const { organization } = useSelector((state) => state.Organization);
  const [chosenTab, setChosenTab] = useState(AVAILABLE_TABS.SUITE);
  const [preparedSuitesCSVData, setPreparedSuitesCSVData] = useState([]);
  const [preparedBatteryTableInfo, setPreparedBatteryTableInfo] = useState([]);
  const [activeSuitePage, setSuiteActivePage] = useState(1);
  const [itemsPerSuitePage, setItemsPerSuitePage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isYardiLoading, setIsYardiLoading] = useState(false);
  const firstRequest = useRef(true);

  // Sometimes building object does not have yardi_id, so we have to check the building in buildings array
  const isYardiBuilding = Boolean(building?.yardi_id || buildings?.find(
    b => b.org_id === building?.org_id && b.id === building?.id
  )?.yardi_id)

  const getSearchedSuites = () => {
    setIsLoading(true);
    dispatch(
      getBuildingSuites({
        orgId: building?.org_id,
        buildingId: building?.id,
        searchText: searchText
      })
    );
  };

  const emptySearchedSuited = () => {
    setIsLoading(true);
    dispatch(
      getBuildingSuites({
        orgId: building?.org_id,
        buildingId: building?.id
      })
    );
  };

  const openAddSuiteModal = () => {
    dispatch(
      changeOpenedModal({
        modal: 'addSuite'
      })
    );
  };

  const setSearchedText = (text) => {
    dispatch(setSearchTextSuite(text));
  };

  const getBuildingSuitesByPage = (page, orgId, buildingId) => {
    if (!page || !orgId || !buildingId) {
      return;
    }

    setIsLoading(true);
    dispatch(
      getBuildingSuites({
        orgId: orgId,
        buildingId: buildingId,
        page: page,
        per_page: itemsPerSuitePage,
        success: () => {},
        fail: () => {
          dispatch(setCSVSuites([]));
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to fetch suites!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const handleSyncYardiSuites = () => {
    setIsYardiLoading(true);
    dispatch(
      syncYardiSuites({
        orgId: building?.org_id,
        buildingId: building?.id,
        success: () => {
          setTimeout(() => {
            setIsYardiLoading(false);
            getBuildingSuitesByPage(activeSuitePage, building?.org_id, building?.id);
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('Units synced successfully!'),
              })
            );
          }, FETCH_YARDI_UNITS_DELAY);
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to sync units'),
              modal: 'errorToast'
            })
          );
        }
      })
    )
  } 

  useEffect(() => {
    if (activeSuitePage > 0 && config?.suite_enabled) {
      getBuildingSuitesByPage(activeSuitePage, building?.org_id, building?.id);
    }
  }, [activeSuitePage]);

  useEffect(() => {
    const buildingIdFromUrl = history.location.pathname.split('/')[2];
    dispatch(resetSearchTextSuite());
    dispatch(
      getBuilding({
        id: buildingIdFromUrl,
        success: (buildingRes) => {
          dispatch(
            getOrgConfig({
              id: buildingRes?.data?.org_id,
              success: (data) => {
                firstRequest.current = false;
                setChosenTab(
                  buildingRes?.data?.config?.suite_enabled ? AVAILABLE_TABS.SUITE : AVAILABLE_TABS.UNITS
                );
                if (buildingRes?.data?.config?.suite_enabled) {
                  getBuildingSuitesByPage(
                    activeSuitePage,
                    buildingRes?.data?.org_id,
                    buildingRes?.data?.id
                  );
                }
              },
              fail: () => {
                dispatch(
                  setToast({
                    toastShow: true,
                    toastMessage: t('Failed to fetch the org config!'),
                    modal: 'errorToast'
                  })
                );
              }
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to fetch the building!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    dispatch(setIsOnDashboard(false)); // to hide the global building DD
    return () => {
      dispatch(clearAmenities());
      dispatch(clearFilteredAmenities());
    };
  }, []);

  // Reset csvSuites when the page is loaded
  useEffect(() => {
    dispatch(setCSVSuites([]));
  }, []);

  useEffect(() => {
    if (chosenTab === AVAILABLE_TABS.SUITE) {
      setPreparedSuitesCSVData(
        csvSuites.map((suite) => {
          return {
            name: suite?.name,
            area: suite?.area,
            floor: suite?.floor,
            rooms: suite?.rooms,
            battery: suite?.devices
              ?.map((device) => `${device.name} ${device?.battery_level}`)
              .join('; ')
          };
        })
      );
    }
  }, [chosenTab, csvSuites]);

  useEffect(() => {
    dispatch(showGlobalBuildingsDropdown(false));
    return () => {
      dispatch(showGlobalBuildingsDropdown(true));
    };
  }, []);

  const TabButtons = {
    [AVAILABLE_TABS.AMENITIES]: 
    (<AddNewButton
      disabled={roleId < UserRole.SUPER_ADMIN && config?.org_allow_edits === false}
      onClick={() => openModal('addAmenity')}
    />),
    [AVAILABLE_TABS.SUITE]: 
    (<>
      <CustomSearchBar
        setValue={setSearchedText}
        handleSearch={getSearchedSuites}
        handleGet={emptySearchedSuited}
        // entitySearch={entity}
        showSelect={false}
        initialValue={searchText}
      />
      <AddNewButton
        disabled={roleId < UserRole.SUPER_ADMIN && config?.org_allow_edits === false}
        onClick={openAddSuiteModal}
      />
      {isYardiPropertyProvider(organization) && isYardiBuilding && config?.suite_enabled && roleId === UserRole.SUPER_ADMIN && (
        <StyledYardiSyncButton isPrimary text={t("Sync Yardi Units")} onClick={handleSyncYardiSuites} disabled={isYardiLoading} />
      )}
    </>),
    [AVAILABLE_TABS.UNITS]: (roleId === UserRole.SUPER_ADMIN || config?.org_allow_edits) ?
    (
      <GlobalAddButton text="Add" modalName="addUnit" />
    ) : null,
  };

  return (
    <div>
      <StyledCard>
        <StyledCardBody>
          <TablePageHeader>
            <StyledLinkHeader>{building && <>{building.name}</>}</StyledLinkHeader>

            <OptionsContainer>
              {
                TabButtons[chosenTab]
              }
              { chosenTab !== AVAILABLE_TABS.AMENITIES &&
                chosenTab !== AVAILABLE_TABS.BATTERY_STATUS &&
                (roleId === UserRole.SUPER_ADMIN || (roleId === UserRole.ADMIN &&
                config?.org_allow_edits)) && <ImportUnitsCSVButton chosenTab={chosenTab} />}
              <ExportCSVButton
                Data={
                  chosenTab === AVAILABLE_TABS.BATTERY_STATUS
                    ? preparedBatteryTableInfo
                    : chosenTab === AVAILABLE_TABS.AMENITIES
                    ? filteredAmenities
                    : chosenTab === AVAILABLE_TABS.SUITE
                    ? preparedSuitesCSVData
                    : units
                }
              />
            </OptionsContainer>
          </TablePageHeader>

          <TabList>
            {config?.suite_enabled && (
              <Tab
                isActive={chosenTab === AVAILABLE_TABS.SUITE}
                onClick={() => setChosenTab(AVAILABLE_TABS.SUITE)}>
                <StyledTabLabel>{t('Units')}</StyledTabLabel>
              </Tab>
            )}
            <Tab
              isActive={chosenTab === AVAILABLE_TABS.UNITS}
              onClick={() => setChosenTab(AVAILABLE_TABS.UNITS)}>
              <StyledTabLabel>
                {t(config?.suite_enabled ? 'Rooms' : 'Units')}
              </StyledTabLabel>
            </Tab>
            <Tab
              isActive={chosenTab === AVAILABLE_TABS.AMENITIES}
              onClick={() => setChosenTab(AVAILABLE_TABS.AMENITIES)}>
              <StyledTabLabel>{t('Amenities')}</StyledTabLabel>
            </Tab>
            <Tab
              isActive={chosenTab === AVAILABLE_TABS.BATTERY_STATUS}
              onClick={() => setChosenTab(AVAILABLE_TABS.BATTERY_STATUS)}>
              <StyledTabLabel>{t('Battery Status')}</StyledTabLabel>
            </Tab>
          </TabList>

          {chosenTab === AVAILABLE_TABS.SUITE ? (
            <Suites
              csvSuites={csvSuites}
              firstRequest={firstRequest}
              activePage={activeSuitePage}
              setActivePage={setSuiteActivePage}
              itemsPerPage={itemsPerSuitePage}
              setItemsPerPage={setItemsPerSuitePage}
              isLoading={isLoading || suitesStatus === requestPending(SYNC_YARDI_SUITES) || isYardiLoading}
              setIsLoading={setIsLoading}
            />
          ) : chosenTab === AVAILABLE_TABS.AMENITIES ? (
            <Amenities />
          ) : chosenTab === AVAILABLE_TABS.BATTERY_STATUS ? (
            <BatteryStatusTable setPreparedBatteryTableInfo={setPreparedBatteryTableInfo} />
          ) : (
            <Unit />
          )}

          <AddUnit />
        </StyledCardBody>
      </StyledCard>
    </div>
  );
};

const StyledLinkHeader = styled(TablePageTitle)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledYardiSyncButton = styled(Button)`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 46px;
  max-width: 180px;
`;

export default UnitAmenities;
